var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { id: "define-user-type" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("h1", [_vm._v("DÉFINIR MON PROFIL UTILISATEUR")]),
              _c("Stepper", { attrs: { steps: _vm.steps, step: 1 } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [_vm.isDriver ? _c("VehicleBlue") : _c("ChargingPointYellow")],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-button-group",
                { staticClass: "switch" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "driver",
                      class: { inactive: !_vm.isDriver },
                      attrs: { disabled: _vm.hasVehicles },
                      on: { click: _vm.toggleUserType }
                    },
                    [_vm._v("CONDUCTEUR")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "provider",
                      class: { inactive: _vm.isDriver },
                      on: { click: _vm.toggleUserType }
                    },
                    [_vm._v("HÔTE")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "buttons" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "light",
                    to: _vm.nextRoute,
                    "router-tag": "button"
                  }
                },
                [_vm._v("SUIVANT")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }