<template>
  <b-container id="define-user-type">
    <b-row>
      <b-col>
        <h1>DÉFINIR MON PROFIL UTILISATEUR</h1>
        <Stepper :steps="steps" :step="1" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <VehicleBlue v-if="isDriver" />
        <ChargingPointYellow v-else />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button-group class="switch">
          <b-button
            class="driver"
            :class="{ inactive: !isDriver }"
            :disabled="hasVehicles"
            @click="toggleUserType"
            >CONDUCTEUR</b-button
          >
          <b-button
            class="provider"
            :class="{ inactive: isDriver }"
            @click="toggleUserType"
            >HÔTE</b-button
          >
        </b-button-group>
      </b-col>
    </b-row>
    <b-row class="buttons">
      <b-col>
        <b-button variant="light" :to="nextRoute" router-tag="button"
          >SUIVANT</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Stepper from '@/components/Registration/Stepper.vue'
import VehicleBlue from '@/components/svg/VehicleBlue.vue'
import ChargingPointYellow from '@/components/svg/ChargingPointYellow.vue'
import { UserType } from '@/assets/js/Constants.js'
import { RegisterProviderStepCount } from '@/views/RegisterProvider.vue'
import { RegisterDriverStepCount } from '@/views/RegisterDriver.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'define-user-type',
  components: { Stepper, VehicleBlue, ChargingPointYellow },
  data() {
    return {
      userType: undefined,
      steps: 4
    }
  },
  computed: {
    ...mapGetters(['hasVehicles']),
    isDriver() {
      return this.userType == UserType.Driver
    },
    nextRoute() {
      return {
        name: 'register-' + this.userType
      }
    }
  },
  mounted() {
    this.loadVehicles({ api: this.$apiService }).then(() => {
      if (this.hasVehicles) {
        this.setProvider()
      } else {
        this.setDriver()
      }
    })
  },
  methods: {
    ...mapActions(['loadVehicles']),
    setDriver() {
      this.userType = UserType.Driver
      this.steps = RegisterDriverStepCount
    },
    setProvider() {
      this.userType = UserType.Provider
      this.steps = RegisterProviderStepCount
    },
    toggleUserType() {
      if (this.isDriver || this.hasVehicles) {
        this.setProvider()
      } else {
        this.setDriver()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles.scss';

#define-user-type {
  text-align: center;

  h1 {
    margin: 111px auto 9px;
    font-size: 13px;
    font-weight: $font-weight-bold;
  }

  .row svg {
    margin: 53px auto 43px;
    width: 230px;
    height: 120px;
  }

  .switch {
    background-color: #f3f3f3;
    border-radius: 10px;

    .btn {
      width: 167.5px;
      height: 82px;
      color: #fff;

      &.driver {
        background-color: #009ff9;
      }

      &.provider {
        background-color: $provider-yellow;
      }

      &.inactive {
        background-color: #f3f3f3;
        color: #989898;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  .buttons {
    margin-top: 143px;
  }
}
</style>
